.homepage
    display: flex
    height: 100%
    flex-direction: column
    overflow: hidden
    .header-container
        flex: 5
        background-image: url(../../static/banner.png)
        background-repeat: no-repeat
        background-size: 100% !important
        background-position: center
        transition-property: background-size, background-position
        transition-duration: .7s
        transition-timing-function: ease
        &:hover
            background-size: 115% !important
        .banner
            display: flex
            width: 100%
            height: 100%
            justify-content: center
            background-color: rgba(255, 255, 255, .3)
            .header-content
                width: 500px
                height: auto
                .title
                    text-align: center
                    color: #252525
                    h1
                        padding-top: 20vh
                        margin-bottom: 0
                        font-size: 70pt
                        font-family: NotoSans-SC-Medium
                    p
                        margin-top: 30px
                        font-size: 14pt
                        font-weight: bold
                    & > *::selection
                        background-color: #fff
                        color: $theme-color
                .quick-start-bar
                    width: 100%
                    text-align: center
                    margin-top: 10vh
                    button
                        background-color: rgba(255, 255, 255, .5)
                        font-size: 11pt
                        padding: .575rem 1rem
                        border: none
                        border-radius: 0
                        transition-property: background-color
                        transition-duration: .2s
                        &:hover
                            background-color: rgba(255, 255, 255, 1)
                    
    .content-container
        flex: 3
        display: flex
        width: 100%
        padding: 3rem 20rem
        .ant-card
            flex: 1
            margin-left: 15px
            margin-right: 15px
            border: none
            border-radius: 0
            background-color: #eee
            padding: 20px
            font-size: 12pt
            .ant-card-head
                font-size: 16pt
                font-weight: bold
                .ant-card-head-title
                    padding-bottom: 10px
                a
                    margin-right: 10px
            .ant-card-body
                padding-top: 10px
                span
                    display: block
            .card-link
                cursor: pointer
    
    .donate
        z-index: 10
        display: flex
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        background-color: rgba(0, 0, 0, .5)
        img
            margin: auto
