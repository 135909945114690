.page
    height: 100%
    padding-top: 50px
    overflow-y: auto
    &::-webkit-scrollbar
        width: 14px
        background-color: #F1F1F1
    &::-webkit-scrollbar-thumb
        background-color: #C1C1C1
        &:hover
            background-color: #A8A8A8
    article
        width: 45%
        margin: 0 auto
        margin-bottom: 150px
        padding-top: 70px
        padding-left: 60px
        padding-right: 60px
        header
            font-weight: bold
            margin-bottom: 30px
        .content-container
            margin-bottom: 30px
            h2, h3, h4, h5, h6
                font-weight: bold
                margin-bottom: 20px
            hr
                margin-top: 30px
            code
                background-color: #efefef
                padding: .09rem .35rem
                border-radius: 4px
                color: #525252
        footer
            a
                font-weight: bold
