@import ./fonts/font
@import ./vars

@import ./nav
@import ./image-box
@import ./server-card
@import ./pages/page
@import ./pages/homepage
@import ./pages/quick-start
@import ./pages/pictures
@import ./pages/not-found

@import ./adaptation

body
    margin: 0
    padding: 0

main
    width: 100vw
    height: 100vh
    font-family: $font

.tooltip
    white-space: pre-wrap
    font-family: monospace

button
    cursor: pointer

a, .ant-btn-link
    color: #0d6efd
    font-size: 1rem
    text-decoration: none
    &:hover
        color: #0a58ca
    &:active
        color: #0d6efd
    &:focus
        color: #0d6efd
