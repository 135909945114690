@font-face
    font-family: NotoSans-SC-Light
    src: url(./NotoSansSC-Light.otf)

@font-face
    font-family: NotoSans-SC-Medium
    src: url(./NotoSansSC-Medium.otf)


@font-face
    font-family: NotoSans-SC-Regular
    src: url(./NotoSansSC-Regular.otf)

