.server-card
    width: 100%
    display: flex
    flex-direction: row
    justify-content: space-between
    border: 1px solid transparent
    margin-bottom: 2px
    background-color: #202020
    color: #fff
    font-family: monospace
    .server-info
        display: flex
        flex-direction: row
        img
            width: 64px
            height: 64px
        .server-description
            padding-top: 2px
            padding-left: 5px
            p, .motd-container
                margin-bottom: 0
                font-size: 10pt
                white-space: pre-wrap
            .motd-container
                color: gray
                .motd
                    &[data-color=lightpurple] // light_purple -> #FF55FF
                        color: #FF55FF
                    &[data-color=darkpurple] // dark_purple -> darkmagenta
                        color: darkmagenta
                    &.bold
                        font-weight: bold
                    &.del
                        text-decoration: line-through
                    &.under
                        text-decoration: underline
                    &.italic
                        font-style: italic
                    &.reset
                        color: #fff
                        text-decoration: none
                        font-style: normal
                        font-weight: 400
    .server-status
        .ant-badge
            display: flex
            flex-direction: row
            padding-top: 7px
            .ant-badge-status-dot
                margin-top: 7px
                &:after
                    border-color: #52c41a
            p
                color: #fff
                margin-right: 7px
