nav
    width: 100vw
    height: 50px
    padding-left: 140px
    padding-right: 140px
    backdrop-filter: blur(24px)
    position: fixed
    box-shadow: rgba(0, 0, 0, .33) 0 1px 10px 1px
    z-index: 100
    ul
        width: 100%
        height: 100%
        margin: 0
        padding: 0
        list-style: none
        li
            float: left
            height: 100%
            font-family: NotoSans-SC-Medium
            cursor: pointer
            &:hover
                background-color: rgba(223, 223, 223, .3)
            &:active
                background-color: rgba(210, 210, 210, .3)
            &::selection
                background-color: transparent
            &.homepage-link
                font-weight: bold
            &.hitokoto
                float: right
                font-family: NotoSans-SC-Light
                background-color: transparent !important
                cursor: default
            a
                display: block
                width: 100%
                height: 100%
                color: #000 !important
                text-decoration: none
            a, &.hitokoto
                padding-left: 20px
                padding-right: 20px
                padding-block-start: .7rem
                padding-block-end: .7rem
