.image-box
    position: relative
    max-width: 100%
    overflow: hidden
    .image-description
        position: absolute
        bottom: 0
        left: 0
        padding-left: 20px
        padding-bottom: 25px
        color: #fff
        h2
            color: #fff
            margin-bottom: 0 !important
            font-family: NotoSans-SC-Medium
        p
            margin-top: 10px
            margin-bottom: 0
